import { Context, evaluate } from '~/utils/evaluate';

export interface TextSegment {
    type: 'default' | 'highlight' | 'icon';
    text: string | { value: string; color: string };
}

export type SmartString = TextSegment[];

const format = {
    percent: (value: number) => `${value}%`,
    fixed: (value: number, precision = 2) => value.toFixed(precision),
    color: (value: string, color: string) => ({ value, color }),
};

// replaces "[expr]" with "the value of expr in the context"
export function getSmartString(text: string, context?: Context): SmartString {
    const enum STATE {
        DEFAULT,
        EXPR,
        TEXT,
    }

    const smartContext = { format, f: format, ...context };

    let state = STATE.DEFAULT;
    let buffer = '';
    const result: TextSegment[] = [];

    const reset = (state = STATE.DEFAULT) => {
        buffer = '';
        return state;
    };

    try {
        for (const char of text) {
            switch (char) {
                case '[':
                    if (state !== STATE.DEFAULT) {
                        throw new Error('state is not default');
                    }
                    if (buffer !== '') {
                        result.push({ type: 'default', text: buffer });
                    }
                    state = reset(STATE.TEXT);
                    break;

                case ']':
                    if (state !== STATE.TEXT) {
                        throw new Error('state is not TEXT');
                    }

                    result.push({ type: 'highlight', text: getText(buffer, smartContext) });
                    state = reset();
                    break;

                case '}':
                    if (state !== STATE.TEXT) {
                        throw new Error('state is not TEXT');
                    }

                    result.push({ type: 'icon', text: getText(buffer, smartContext) });
                    state = reset();
                    break;

                case '{':
                    if (state !== STATE.DEFAULT) {
                        throw new Error('state is not default');
                    }
                    if (buffer !== '') {
                        result.push({ type: 'icon', text: buffer });
                    }
                    state = reset(STATE.TEXT);
                    break;

                default:
                    buffer += char;
            }
        }

        if (state !== STATE.DEFAULT) {
            throw new Error('state is not DEFAULT');
        }
        if (buffer !== '') {
            result.push({ type: 'default', text: buffer });
        }
        reset();
    } catch (e) {
        console.error(`Error in getSmartString '${text}': ${e}.`);
        return [{ type: 'default', text }];
    }

    return result;
}

function getText(query: string, context?: Context): string {
    const [result] = evaluate(query, context);

    return result ?? query;
}
