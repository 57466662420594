/* eslint-disable @typescript-eslint/no-explicit-any */
export type Context = Record<string, unknown>;

enum TokenType {
    Whitespace = 'Whitespace',
    Identifier = 'Identifier',
    NumberLiteral = 'NumberLiteral',
    StringLiteral = 'StringLiteral',
    OperatorOrPunctuator = 'OperatorOrPunctuator',
    EndOfFile = 'EndOfFile',
}

const regexes = {
    [TokenType.Whitespace]: /\s+/,
    [TokenType.Identifier]: /[a-zA-Z_][a-zA-Z0-9_]*/,
    [TokenType.NumberLiteral]: /-?\d+(\.\d+)?/,
    [TokenType.StringLiteral]: /("[^"]*")|('[^']*')/,
    // eslint-disable-next-line no-useless-escape
    [TokenType.OperatorOrPunctuator]: /===|==|!==|!=|<=|>=|&&|\|\||[\,\.\[\]\(\)!<>\?]/,
};

interface Token {
    type: TokenType;
    lexeme: string;
}

const combinedRegex = new RegExp(Object.entries(regexes).map(([name, regex]) => `(?<${name}>${regex.source})`).join('|'), 'g');

function* tokenize(source: string): Generator<Token> {
    let lastPos = 0;

    for (const match of source.matchAll(combinedRegex)) {
        if (match.groups && match.index !== undefined) {
            if (lastPos < match.index) {
                throw new Error(`Skipped "${source.substring(lastPos, match.index)}"`);
            }

            const lexeme = match[0];
            const type = Object.entries(match.groups).find(([, value]) => value !== undefined)?.[0];

            lastPos = match.index + lexeme.length;

            if (type) {
                yield { type: type as TokenType, lexeme };
            }
        }
    }

    if (lastPos < source.length) {
        throw new Error(`Skipped "${source.substring(lastPos)}"`);
    }
}

type BoolOperator = '&&' | '||';
type EqualityOperator = '==' | '===' | '!=' | '!==';
type RelationalOperator = '<' | '<=' | '>' | '>=';
type UnaryOperator = '!';

type ASTNode<T extends string> = {
    type: T;
    /**
     * The string representation of the node. This is useful for debugging.
     */
    lexeme: string;
    optional?: boolean;
};

type Expr = BoolExpr | EqualityExpr | RelationalExpr | Primary;
type BoolExpr = ASTNode<'boolExpr'> & { left: Expr; operator: BoolOperator; right: Expr };
type EqualityExpr = ASTNode<'equalityExpr'> & { left: Expr; operator: EqualityOperator; right: Expr };
type RelationalExpr = ASTNode<'relationalExpr'> & { left: Expr; operator: RelationalOperator; right: Expr };
type Primary = MemberAccess | IndexAccess | Call | Primitive;
type MemberAccess = ASTNode<'memberAccess'> & { object: Expr; property: Identifier };
type IndexAccess = ASTNode<'indexAccess'> & { object: Expr; index: Expr };
type Call = ASTNode<'call'> & { object: Expr; args: Expr[] };
type Primitive = Parentheses | Unary | Identifier | NumberLiteral | StringLiteral;
type Parentheses = ASTNode<'parentheses'> & { expr: Expr };
type Unary = ASTNode<'unary'> & { operator: UnaryOperator; expr: Expr };
type Identifier = ASTNode<'identifier'> & { name: string };
type NumberLiteral = ASTNode<'numberLiteral'> & { value: number };
type StringLiteral = ASTNode<'stringLiteral'> & { value: string };

function parse(tokens: Token[]): Expr {
    //eslint-disable-next-line no-param-reassign
    tokens = tokens.filter(token => token.type !== TokenType.Whitespace).concat({ type: TokenType.EndOfFile, lexeme: '' });
    let index = 0;

    const current = () => tokens[index];
    const readNextToken = () => index++;
    const skipIf = (lexeme: string) => {
        if (current().lexeme === lexeme) {
            readNextToken();
            return true;
        }

        return false;
    };

    const parse = (): Expr => {
        const expr = parseExpr();
        if (current().type !== TokenType.EndOfFile) {
            throw new Error(`Unexpected token: ${current().lexeme}`);
        }
        return expr;
    };
    const parseExpr = (): Expr => parseBoolExpr();
    const parseBoolExpr = (): Expr => {
        let left: Expr = parseEqualityExpr();
        for (; ;) {
            const { lexeme } = current();
            if (skipIf('&&') || skipIf('||')) {
                const right = parseEqualityExpr();
                left = { type: 'boolExpr', lexeme, left, operator: lexeme as BoolOperator, right };
            } else {
                break;
            }
        }

        return left;
    };
    const parseEqualityExpr = (): Expr => {
        let left: Expr = parseRelationalExpr();
        for (; ;) {
            const { lexeme } = current();
            if (skipIf('==') || skipIf('===') || skipIf('!=') || skipIf('!==')) {
                const right = parseRelationalExpr();
                left = { type: 'equalityExpr', lexeme, left, operator: lexeme as EqualityOperator, right };
            } else {
                break;
            }
        }

        return left;
    };
    const parseRelationalExpr = (): Expr => {
        let left: Expr = parsePrimary();
        for (; ;) {
            const { lexeme } = current();
            if (skipIf('<') || skipIf('<=') || skipIf('>') || skipIf('>=')) {
                const right = parseRelationalExpr();
                left = { type: 'relationalExpr', lexeme, left, operator: lexeme as RelationalOperator, right };
            } else {
                break;
            }
        }

        return left;
    };
    const parsePrimary = (): Primary => {
        let expr: Expr = parsePrimitive();
        for (; ;) {
            const optional = skipIf('?');
            const dot = skipIf('.');

            if (skipIf('[')) {
                if (optional && !dot) {
                    throw new Error('Expected "." before "[" for optional member access');
                }

                const index = parseExpr();
                if (!skipIf(']')) {
                    throw new Error('Expected "]"');
                }
                expr = {
                    type: 'indexAccess',
                    lexeme: `${expr.lexeme}${optional ? '?' : ''}${dot ? '.' : ''}[${index.lexeme}]`,
                    object: expr,
                    index,
                    optional: optional || expr.optional,
                };
            } else if (skipIf('(')) {
                if (optional && !dot) {
                    throw new Error('Expected ." before "(" for optional call');
                }

                const args: Expr[] = [];
                for (; ;) {
                    if (skipIf(')')) {
                        break;
                    }
                    args.push(parseExpr());
                    if (!skipIf(',')) {
                        if (!skipIf(')')) {
                            throw new Error('Expected ")" or ","');
                        }
                        break;
                    }
                }
                expr = {
                    type: 'call',
                    lexeme: `${expr.lexeme}${optional ? '?' : ''}${dot ? '.' : ''}(${args.map(arg => arg.lexeme).join(',')})`,
                    object: expr,
                    args,
                    optional: optional || expr.optional,
                };

            } else if (dot) {
                const property = parseIdentifier();
                expr = {
                    type: 'memberAccess',
                    lexeme: `${expr.lexeme}${optional ? '?' : ''}.${property.lexeme}`,
                    object: expr,
                    property,
                    optional: optional || expr.optional,
                };
            } else {
                break;
            }
        }

        return expr;
    };
    const parsePrimitive = (): Primitive => {
        if (skipIf('(')) {
            const expr = parseExpr();
            if (!skipIf(')')) {
                throw new Error('Expected ")"');
            }
            return { type: 'parentheses', lexeme: `(${expr.lexeme})`, expr };
        } else if (skipIf('!')) {
            const expr = parsePrimary();
            return { type: 'unary', lexeme: `!${expr.lexeme}`, operator: '!', expr };
        } else if (current().type === TokenType.Identifier) {
            return parseIdentifier();
        } else if (current().type === TokenType.NumberLiteral) {
            return parseNumberLiteral();
        } else if (current().type === TokenType.StringLiteral) {
            return parseStringLiteral();
        }
        throw new Error(`Unexpected token: ${current().lexeme}`);
    };
    const parseIdentifier = (): Identifier => {
        const token = current();
        if (token.type !== TokenType.Identifier) {
            throw new Error(`Expected identifier, got '${token.lexeme}'`);
        }
        readNextToken();
        return { type: 'identifier', lexeme: token.lexeme, name: token.lexeme };
    };
    const parseNumberLiteral = (): NumberLiteral => {
        const token = current();
        if (token.type !== TokenType.NumberLiteral) {
            throw new Error(`Expected number literal, got '${token.lexeme}'`);
        }
        readNextToken();
        return { type: 'numberLiteral', lexeme: token.lexeme, value: parseFloat(token.lexeme) };
    };
    const parseStringLiteral = (): StringLiteral => {
        const token = current();
        if (token.type !== TokenType.StringLiteral) {
            throw new Error(`Expected string literal, got '${token.lexeme}'`);
        }
        readNextToken();
        return { type: 'stringLiteral', lexeme: token.lexeme, value: token.lexeme.slice(1, -1) };
    };

    return parse();
}

function evaluateInternal(expression: string, context?: Context): any {
    const tokens = [...tokenize(expression)];
    const expr = parse(tokens);

    const visitExpr = (expr: Expr): any => {
        switch (expr.type) {
            case 'boolExpr': return visitBoolExpr(expr);
            case 'equalityExpr': return visitEqualityExpr(expr);
            case 'relationalExpr': return visitRelationalExpr(expr);
            default: return visitPrimary(expr);
        }
    };
    const visitBoolExpr = (expr: BoolExpr): any => {
        const left = visitExpr(expr.left);
        const right = () => visitExpr(expr.right);

        switch (expr.operator) {
            case '&&': return left && right();
            case '||': return left || right();
        }
    };
    const visitEqualityExpr = (expr: EqualityExpr): any => {
        const left = visitExpr(expr.left);
        const right = visitExpr(expr.right);

        switch (expr.operator) {
            // eslint-disable-next-line eqeqeq
            case '==': return left == right;
            case '===': return left === right;
            // eslint-disable-next-line eqeqeq
            case '!=': return left != right;
            case '!==': return left !== right;
        }
    };
    const visitRelationalExpr = (expr: RelationalExpr): any => {
        const left = visitExpr(expr.left);
        const right = visitExpr(expr.right);

        switch (expr.operator) {
            case '<': return left < right;
            case '<=': return left <= right;
            case '>': return left > right;
            case '>=': return left >= right;
        }
    };
    const visitPrimary = (expr: Primary): any => {
        switch (expr.type) {
            case 'memberAccess': return visitMemberAccess(expr);
            case 'indexAccess': return visitIndexAccess(expr);
            case 'call': return visitCall(expr);
            default: return visitPrimitive(expr);
        }
    };
    const visitMemberAccess = (expr: MemberAccess): any => {
        const object = visitExpr(expr.object);
        const property = expr.property.name;
        const optional = expr.optional;

        if (optional && (object === null || object === undefined)) {
            return undefined;
        }

        if (typeof object !== 'object' || object === null) {
            throw new Error(`Cannot access property "${property}" of non-object "${expr.object.lexeme}"`);
        }

        return object[property];
    };
    const visitIndexAccess = (expr: IndexAccess): any => {
        const object = visitExpr(expr.object);
        const index = visitExpr(expr.index);
        const optional = expr.optional;

        if (optional && (object === null || object === undefined)) {
            return undefined;
        }

        if (typeof object !== 'object' || object === null) {
            throw new Error(`Cannot access index "${index}" of non-object "${expr.object.lexeme}"`);
        }

        return object[index];
    };
    const visitCall = (expr: Call): any => {
        const object = visitExpr(expr.object);
        const args = expr.args.map(visitExpr);
        const optional = expr.optional;

        if (optional && (object === null || object === undefined)) {
            return undefined;
        }

        if (typeof object !== 'function') {
            throw new Error(`Cannot call non-function "${expr.object.lexeme}"`);
        }

        return object(...args);
    };
    const visitPrimitive = (expr: Primitive): any => {
        switch (expr.type) {
            case 'parentheses': return visitExpr(expr.expr);
            case 'unary': return visitUnary(expr);
            case 'identifier': return visitIdentifier(expr);
            case 'numberLiteral': return visitNumberLiteral(expr);
            case 'stringLiteral': return visitStringLiteral(expr);
        }
    };
    const visitUnary = (expr: Unary): any => {
        const value = visitExpr(expr.expr);

        switch (expr.operator) {
            case '!': return !value;
        }
    };
    const visitIdentifier = (expr: Identifier): any => {
        switch (expr.name) {
            case 'true': return true;
            case 'false': return false;
            case 'null': return null;
            case 'undefined': return undefined;
            default: return context?.[expr.name];
        }
    };
    const visitNumberLiteral = (expr: NumberLiteral): any => expr.value;
    const visitStringLiteral = (expr: StringLiteral): any => expr.value;

    return visitExpr(expr);
}

export function evaluate(expression: string, context?: Context): [any, null] | [null, Error] {
    try {
        const result = evaluateInternal(expression, context);
        return [result, null];
    } catch (error) {
        return [null, error as Error];
    }
}

export function test(expression: string, context?: Context): boolean {
    try {
        return !!evaluateInternal(expression, context);
    } catch {
        return false;
    }
}
